import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserKeys } from '@prc-lib-core';
import { AppState } from '../app-state.model';
import { UserState } from '../reducers/user.reducer';

export const selectUser = (state: AppState) => state.User;

export const getUserState = createFeatureSelector<UserState>('User');

export const getUserEmail = createSelector(selectUser, (state: UserState) => state.email);

export const getUserData = createSelector(selectUser, (state: UserState) => state);

export const getUserUid = createSelector(selectUser, (state: UserState) => state.uid);

export const getUserDataValue = (key: UserKeys) => createSelector(selectUser, (data: UserState) => data[key]);
